import * as React from "react";

import logo from "../../logo.svg";
import { lookups, photos, settings, users } from "../../Includes/db";
import { useAuth } from "../../Contexts/AuthContext";
import ApiService from "../../Services/ApiService";
import { useConfig } from "../../Contexts/ConfigContext";
import UpdateAvailable from "../../Components/UpdateAvailable";
import { get } from "../../Includes/Utilities";

const Loader = ({ message, onLoaded }) => {
  const [loadingMessage, setLoadingMessage] = React.useState(message ? message : "Loading...");
  const [error, setError] = React.useState(false);

  const auth = useAuth();
  const config = useConfig();

  React.useEffect(() => {
    // Fetch the token from storage then navigate to our appropriate place
    const bootstrapConfigAsync = async () => {
      setError(false);
      const api = new ApiService(auth.apiUrl, "", auth.token);

      // check the API is accessible
      setLoadingMessage("Checking connectivity...");
      await api
        .ping()
        .then((response) => response.data.message === "pong")
        .then(async (apiAvailable) => {
          if (!apiAvailable) return;

          // download config
          setLoadingMessage("Downloading config...");
          const updatedAt = await settings.get("configUpdatedAt");

          await api
            .downloadConfiguration("app", updatedAt ? updatedAt.value : "")
            .then(async (response) => {
              //TODO: split lookups out of config

              const { lookups: confLookups, logo, ...restConf } = response.data.config;

              for (const [key, value] of Object.entries(confLookups)) {
                await lookups.put({ key, value });
              }

              await settings.bulkPut([
                { key: "logo", value: logo },
                { key: "config", value: { config: restConf } },
                { key: "configUpdatedAt", value: response.data.updatedAt },
              ]);
              return { value: response.data };
            })
            .catch(async (_err) => {
              const currentConfig = await settings.get("config");
              if (currentConfig) return currentConfig;
              throw new Error("No config available");
            })
            .then((currentConfig) => {
              config.setConfig(currentConfig.value.config);

              if (currentConfig.value.config.syncJobs) {
                setLoadingMessage("Downloading jobs...");

                const { before, after, ...query } = currentConfig.value.config.syncJobs;
                if (after) {
                  const afterQ = new Date();
                  afterQ.setDate(afterQ.getDate() + Number(after));
                  query.after = afterQ;
                }
                if (before) {
                  const beforeQ = new Date();
                  beforeQ.setDate(beforeQ.getDate() + Number(before));
                  query.before = beforeQ;
                }

                return api
                  .resource("batch")
                  .search({ deleted: false, inspections: true, ...query })
                  .list()
                  .then(async (response) => {
                    const jobs = response.data.docs.map((j) => ({
                      ...j,
                      inspections: j.inspections.map((i) => ({
                        _remotePhotos: i.photos,
                        _remoteDamages: i.damages,
                        identification: i.identification,
                        type: i.type,
                        meta: i.meta
                          ? Object.entries(i.meta).reduce((acc, m) => {
                              const value = get(m[1], "value");
                              return {
                                ...acc,
                                [m[0]]: value.length === 1 ? value[0] : value,
                              };
                            }, {})
                          : {},
                      })),
                    }));

                    await settings.put({ key: "jobs", value: jobs });
                    return currentConfig;
                  })
                  .catch((e) => {
                    console.error(e);
                    return currentConfig;
                  });
              }

              return currentConfig;
            })
            .catch((e) => {
              setLoadingMessage(
                <>
                  Error loading Config
                  <br />
                  <br />
                  <small className="font-normal normal-case">Make sure you have internet access and reload the app to try again. If the issue continues, contact the administrator.</small>
                </>,
              );
              setError(true);
              return null;
            });
        })

        .catch(async (_err) => {
          const currentConfig = await settings.get("config");

          if (!currentConfig) throw new Error("No config found");
          const logo = await settings.get("logo");

          const storedLookups = await lookups.toArray();

          currentConfig.value.config.logo = logo ? logo.value : undefined;
          currentConfig.value.config.lookups = {};

          for (const storedLookup in storedLookups) {
            currentConfig.value.config.lookups[storedLookups[storedLookup].key] = storedLookups[storedLookup].value;
          }

          if (currentConfig) return config.setConfig(currentConfig.value.config);
          setLoadingMessage(
            <>
              Error loading Config
              <br />
              <br />
              <small className="font-normal normal-case">Make sure you have internet access and reload the app to try again. If the issue continues, contact the administrator.</small>
            </>,
          );
          setError(true);
          return null;
        })
        .catch(async (_err) => {
          setLoadingMessage(
            <>
              Error loading config
              <br />
              <br />
              <small className="font-normal normal-case">Make sure you have internet access and reload the app to try again. If the issue continues, contact the administrator.</small>
            </>,
          );
          setError(true);
          return null;
        });
    };

    if (auth.ready && auth.apiUrl && auth.token) {
      bootstrapConfigAsync();
    } else if (auth.ready && typeof onLoaded === "function") {
      onLoaded();
    }
  }, [auth.ready]);

  React.useEffect(() => {
    // Fetch the token from storage then navigate to our appropriate place
    const bootstrapUsersAsync = async () => {
      setError(false);
      const api = new ApiService(auth.apiUrl, "", auth.token);

      // check the API is accessible
      setLoadingMessage("Downloading users...");
      await api
        .downloadUsers()
        .then(async (response) => {
          const mapped = response.data.map((u) => ({
            id: u._id,
            name: u.name,
            pin: u.pin,
          }));

          const clear = await users.clear();
          const put = await users.bulkPut(mapped);

          // alert(JSON.stringify({ clear, put }));
          return true;
        })
        .catch(console.error)
        .then(async () => {
          const userCount = await users.count();
          if (!config.config || userCount === 0) {
            setLoadingMessage(
              <>
                Error loading users
                <br />
                <br />
                <small className="font-normal normal-case">Make sure you have internet access and reload the app to try again. If the issue continues, contact the administrator.</small>
              </>,
            );
            setError(true);
          } else {
            config.setReady(true);
            if (typeof onLoaded === "function") onLoaded();
          }
          return null;
        })
        .catch((err) => {
          setLoadingMessage(
            <>
              Error loading users
              <br />
              <br />
              <small className="font-normal normal-case">Make sure you have internet access and reload the app to try again. If the issue continues, contact the administrator.</small>
            </>,
          );
          setError(true);
          return null;
        });
    };

    if (auth.ready && auth.apiUrl && auth.token && config.config) bootstrapUsersAsync();
  }, [config.config]);

  return (
    <div className="flex-1 p-5 pb-16 relative text-center">
      <UpdateAvailable />
      <div className={`inset-0 py-5 ${loadingMessage !== "" ? "pb-10" : ""} w-full relative`}>
        <img src={logo} className="inline-block" alt="logo" />
      </div>
      <div className="w-full inset-x-5 bottom-5 text-center">
        {!error && <div className="loader mx-auto mb-8 text-2xl" />}
        <div className="uppercase font-bold text-sm">{auth.ready ? loadingMessage : "Starting up..."}</div>
        {error && (
          <button className="mt-5 mx-auto text-xs font-bold uppercase px-4 py-2 border rounded block leading-normal" onClick={() => window.location.reload()}>
            Reload
          </button>
        )}
      </div>
      {error && auth.uniqueId && (
        <div className="block w-full text-center sm:text-center text-sm">
          <small>Device ID: {auth.uniqueId}</small>
        </div>
      )}
    </div>
  );
};

export default Loader;
