import {faTimes} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React, {useEffect, useReducer, useState} from 'react';
import {createPortal} from 'react-dom';
import CameraBarcodeScanner from "../Components/CameraBarcodeScanner";
import {settings} from "../Includes/db";

function voidFunc() {
}

export const CameraScanContext = React.createContext({
  enabled: false,
  setEnabled: voidFunc,
  setValueFunc: (_v) => {
  },
  reset: voidFunc
});

export const useCameraScan = () => {
  return React.useContext(CameraScanContext);
};

export const CameraScanProvider = props => {
  function reset() {
    setValueFunc(null);
    setShowScanner(false)
  }

  const [enabled, setEnabled] = useState(false);
  const [showScanner, setShowScanner] = useState(false);
  const [valueFunc, setValueFunc] = useReducer((_v, a) => a, null)

  useEffect(() => {
    const bootstrapAsync = async () => {
      const cameraScan = await settings.get('cameraScanEnabled');
      if (cameraScan) setEnabled(cameraScan.value);
    };

    bootstrapAsync();
  }, []);

  useEffect(() => {
    if (typeof valueFunc === 'function') setShowScanner(true)
    else if (showScanner === true) setShowScanner(false)
  }, [valueFunc, showScanner]);

  function wrappedValueFunc(value) {
    let fakeEvent = {
      target: {value},
      nativeEvent: {data: value},
      persist: voidFunc
    }
    if (valueFunc && typeof valueFunc === 'function') valueFunc(fakeEvent);
    reset();
  }

  return (
    <CameraScanContext.Provider value={{enabled, setEnabled, setValueFunc, reset}}>
      <div className={showScanner ? 'overflow-hidden' : ''}>{props.children}</div>
      {showScanner && createPortal((
          <div className={`fixed z-[9988] inset-0 flex items-center justify-center bg-black bg-opacity-75`}>
            <div
              type='button'
              className={`cursor-pointer aspect-1 w-10 flex items-center justify-center absolute z-30 top-3 right-3 text-white`}
              onClick={reset}>
              <FontAwesomeIcon icon={faTimes} size='2x' className='align-middle'></FontAwesomeIcon>
            </div>
            <CameraBarcodeScanner onBarcode={(v)=>{wrappedValueFunc(v)}} />
          </div>
        )
        , document.body)}
    </CameraScanContext.Provider>
  );

};
