export const tryParseJSON = (str) => {
  try {
    return JSON.parse(str);
  } catch (e) {
    return false;
  }
};

export const get = (obj, path, defaultValue) => {
  if (!path) return defaultValue;
  const value = path.split(".").reduce((o, i) => o?.[i], obj);
  return typeof value !== "undefined" && value !== null ? value : defaultValue;
};

export const formatSingleOption = (o) => {
  if (o.value && o.label) return o;

  if (o.key && o.value && Array.isArray(o.value)) {
    return {
      options: formatOptions(o.value),
      label: o.name ? o.name : o.key,
    };
  }

  if (o.key && o.value) {
    return { value: o.key, label: o.value };
  }

  return { value: o, label: o };
};

export const formatOptions = (field, config, job, asset) => {
  let options = field.options ? field.options : false;

  if (options === false && Array.isArray(field)) options = field;

  if (options === false && !field.lookup) return [];

  if (!field.options && field.lookup && Array.isArray(config.config.lookups?.[field.lookup])) {
    options = config.config.lookups[field.lookup];
  }

  if (field.filter) {
    let filterValue;
    const filterKey = field.filter;
    if (typeof filterKey === "string" && filterKey.indexOf(".meta") > 0) {
      const [parent, prop] = filterKey.split(".meta.");
      switch (parent) {
        case "Batch":
          filterValue = job?.[prop];
          break;

        case "Inspection":
          filterValue = asset?.[prop];
          break;

        default:
          console.log(`Unexpected filter parent for "${field.filter}"`);
          break;
      }
    }
    if (filterValue) {
      options = options.filter((o) => o.key === (filterValue.value ? filterValue.value : filterValue));
    }
  }

  const output = options !== false ? options.map((o) => formatSingleOption(o)) : false;

  return output;
};

export const dataURLtoFile = (dataurl, filename) => {
  const arr = dataurl.split(",");
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n) {
    u8arr[n - 1] = bstr.charCodeAt(n - 1);
    n -= 1; // to make eslint happy
  }
  return new File([u8arr], filename, { type: mime });
};

export const remember = async (key, value) => {
  await localStorage.setItem(`persisted_${key}`, JSON.stringify(value));
};

export const recall = async (key) => {
  const v = await localStorage.getItem(`persisted_${key}`);
  return v !== "undefined" ? JSON.parse(v) : undefined;
};

export const timeout = (delay) => {
  return new Promise((res) => setTimeout(res, delay));
};

export const debounce = (func, wait) => {
  let timeout;
  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
};

export const isToday = (someDate) => {
  const today = new Date();
  return someDate.getDate() === today.getDate() && someDate.getMonth() === today.getMonth() && someDate.getFullYear() === today.getFullYear();
};

export const savePhotoFS = async (fileName, data) => {
  const opfsRoot = await navigator.storage.getDirectory();
  const photosDirectory = await opfsRoot.getDirectoryHandle("photos", { create: true });
  const photoFileHandle = await photosDirectory.getFileHandle(fileName, { create: true });
  const photoFileWritable = await photoFileHandle.createWritable();

  await photoFileWritable.write(data);
  await photoFileWritable.close();
};

// export const readPhotoFS = async (fileName) => {
//   const opfsRoot = await navigator.storage.getDirectory();
//   const photosDirectory = await opfsRoot.getDirectoryHandle("photos");
//   const photoFileHandle = await photosDirectory.getFileHandle(fileName);
//   const photoFile = await photoFileHandle.getFile();
//   return
// };

// Copied from
// download.js v4.21, by dandavis; 2008-2018. [MIT] see http://danml.com/download.html for tests/usage
export const download = (data, strFileName, strMimeType) => {
  var self = window, // this script is only for browsers anyway...
    defaultMime = "application/octet-stream", // this default mime also triggers iframe downloads
    mimeType = strMimeType || defaultMime,
    payload = data,
    url = !strFileName && !strMimeType && payload,
    anchor = document.createElement("a"),
    toString = function (a) {
      return String(a);
    },
    myBlob = self.Blob || self.MozBlob || self.WebKitBlob || toString,
    fileName = strFileName || "download",
    blob,
    reader;
  myBlob = myBlob.call ? myBlob.bind(self) : Blob;

  if (String(this) === "true") {
    //reverse arguments, allowing download.bind(true, "text/xml", "export.xml") to act as a callback
    payload = [payload, mimeType];
    mimeType = payload[0];
    payload = payload[1];
  }

  if (url && url.length < 2048) {
    // if no filename and no mime, assume a url was passed as the only argument
    fileName = url.split("/").pop().split("?")[0];
    anchor.href = url; // assign href prop to temp anchor
    if (anchor.href.indexOf(url) !== -1) {
      // if the browser determines that it's a potentially valid url path:
      var ajax = new XMLHttpRequest();
      ajax.open("GET", url, true);
      ajax.responseType = "blob";
      ajax.onload = function (e) {
        download(e.target.response, fileName, defaultMime);
      };
      setTimeout(function () {
        ajax.send();
      }, 0); // allows setting custom ajax headers using the return:
      return ajax;
    } // end if valid url?
  } // end if url?

  //go ahead and download dataURLs right away
  if (/^data:([\w+-]+\/[\w+.-]+)?[,;]/.test(payload)) {
    if (payload.length > 1024 * 1024 * 1.999 && myBlob !== toString) {
      payload = dataUrlToBlob(payload);
      mimeType = payload.type || defaultMime;
    } else {
      return navigator.msSaveBlob // IE10 can't do a[download], only Blobs:
        ? navigator.msSaveBlob(dataUrlToBlob(payload), fileName)
        : saver(payload); // everyone else can save dataURLs un-processed
    }
  } else {
    //not data url, is it a string with special needs?
    if (/([\x80-\xff])/.test(payload)) {
      var i = 0,
        tempUiArr = new Uint8Array(payload.length),
        mx = tempUiArr.length;
      for (i; i < mx; ++i) tempUiArr[i] = payload.charCodeAt(i);
      payload = new myBlob([tempUiArr], { type: mimeType });
    }
  }
  blob = payload instanceof myBlob ? payload : new myBlob([payload], { type: mimeType });

  function dataUrlToBlob(strUrl) {
    var parts = strUrl.split(/[:;,]/),
      type = parts[1],
      indexDecoder = strUrl.indexOf("charset") > 0 ? 3 : 2,
      decoder = parts[indexDecoder] == "base64" ? atob : decodeURIComponent,
      binData = decoder(parts.pop()),
      mx = binData.length,
      i = 0,
      uiArr = new Uint8Array(mx);

    for (i; i < mx; ++i) uiArr[i] = binData.charCodeAt(i);

    return new myBlob([uiArr], { type: type });
  }

  function saver(url, winMode) {
    if ("download" in anchor) {
      //html5 A[download]
      anchor.href = url;
      anchor.setAttribute("download", fileName);
      anchor.className = "download-js-link";
      anchor.innerHTML = "downloading...";
      anchor.style.display = "none";
      // anchor.addEventListener("click", function (e) {
      //   e.stopPropagation();
      //   this.removeEventListener("click", arguments.callee);
      // });
      document.body.appendChild(anchor);
      setTimeout(function () {
        anchor.click();
        document.body.removeChild(anchor);
        if (winMode === true) {
          setTimeout(function () {
            self.URL.revokeObjectURL(anchor.href);
          }, 250);
        }
      }, 66);
      return true;
    }

    //do iframe dataURL download (old ch+FF):
    var f = document.createElement("iframe");
    document.body.appendChild(f);

    if (!winMode && /^data:/.test(url)) {
      // force a mime that will download:
      url = "data:" + url.replace(/^data:([\w\/\-\+]+)/, defaultMime);
    }
    f.src = url;
    setTimeout(function () {
      document.body.removeChild(f);
    }, 333);
  } //end saver

  if (navigator.msSaveBlob) {
    // IE10+ : (has Blob, but not a[download] or URL)
    return navigator.msSaveBlob(blob, fileName);
  }

  if (self.URL) {
    // simple fast and modern way using Blob and URL:
    saver(self.URL.createObjectURL(blob), true);
  } else {
    // handle non-Blob()+non-URL browsers:
    if (typeof blob === "string" || blob.constructor === toString) {
      try {
        return saver("data:" + mimeType + ";base64," + self.btoa(blob));
      } catch (y) {
        return saver("data:" + mimeType + "," + encodeURIComponent(blob));
      }
    }

    // Blob but not URL support:
    reader = new FileReader();
    reader.onload = function (e) {
      saver(this.result);
    };
    reader.readAsDataURL(blob);
  }
  return true;
}; /* end download() */
