import { faTimes } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { createPortal } from "react-dom";

export const ModalContext = React.createContext();

export const useModal = () => {
  return React.useContext(ModalContext);
};

export const ModalProvider = (props) => {
  const [modal, setModal] = React.useState(false);

  React.useEffect(() => {
    if (modal) {
      document.body.classList.add("!overflow-hidden");
    } else {
      document.body.classList.remove("!overflow-hidden");
    }

    // Cleanup function
    return () => {
      document.body.classList.remove("!overflow-hidden");
    };
  }, [modal]); // Re-run the effect when `modal` changes

  return (
    <ModalContext.Provider value={{ setModal }}>
      <div className={modal ? "overflow-hidden" : ""}>{props.children}</div>
      {modal &&
        createPortal(
          <div className={`fixed z-[9999] inset-0 flex items-center justify-center ${modal.light ? "bg-white" : "bg-black"} ${modal.solid ? "" : "bg-opacity-75"}`}>
            {modal.showClose && (
              <div
                type="button"
                className={`cursor-pointer aspect-1 w-10 flex items-center justify-center absolute z-30 top-3 right-3 ${modal.light ? "bg-white text-black" : "bg-black text-white"}`}
                onClick={() => setModal(false)}
              >
                <FontAwesomeIcon icon={faTimes} size="2x" className="align-middle"></FontAwesomeIcon>
              </div>
            )}
            {modal.content || modal}
          </div>,
          document.body,
        )}
    </ModalContext.Provider>
  );
};
