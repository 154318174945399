import "./ReactotronConfig";
import React, { useEffect } from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter, createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from "react-router-dom";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import "./index.css";
import { settings } from "./Includes/db";
import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://c51398b8cb524d19bc48ed8adfe34f50@sentry.bisongrid.dev/4",

  environment: window.location.hostname,

  debug: process.env.NODE_ENV !== "production",

  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 1.0,

  // Performance Monitoring
  integrations: [
    Sentry.browserTracingIntegration(),

    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),

    Sentry.captureConsoleIntegration({
      levels: ["fatal", "error", "warning"],
    }),

    Sentry.replayIntegration({
      maskAllText: false,
      maskAllInputs: false,
      blockAllMedia: true,
    }),

    Sentry.extraErrorDataIntegration(),

    // DexieIntegration(),
  ],
  tracesSampleRate: process.env.NODE_ENV === "production" ? 0.1 : 1, // Capture 10% in (prod) or 100% (dev) of the transactions, reduce in production!
});

const root = createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>,
);

serviceWorkerRegistration.register({
  onUpdate: (registration) => {
    if (registration.waiting) {
      settings.put({ key: "updateAvailable", value: true });
    }
  },
});
