import React from 'react'
import Webcam from 'react-webcam'
import {BrowserMultiFormatReader} from "@zxing/browser";
import {readBarcodesFromImageFile, setZXingModuleOverrides} from "zxing-wasm/reader";

setZXingModuleOverrides({
    locateFile(path, prefix) {
      if (path.endsWith(".wasm")) {
        return `${window.location.origin}/${path}`;
      }
      return prefix + path;
    }
  }
)

const CameraBarcodeScanner = ({onBarcode, onError}) => {
  const webcamRef = React.useRef(null)
  const codeReader = React.useMemo(() => new BrowserMultiFormatReader(), []);

  const capture = React.useCallback(
    async () => {
      if (WebAssembly) {
        const canvasElement = webcamRef?.current?.getCanvas();

        if (!canvasElement) return null

        const blob = await new Promise((resolve) => {
          canvasElement.toBlob((blob) => resolve(blob), 'image/jpeg')
        })

        const results = await readBarcodesFromImageFile(blob)

        if (results?.length > 0 && results[0]?.text) {
          onBarcode(results[0].text);
        }

        return null
      } else {
        const imageSrc = webcamRef?.current?.getScreenshot()
        if (imageSrc) {
          codeReader.decodeFromImageUrl(imageSrc).then(result => {
            if (typeof onBarcode === 'function') onBarcode(result.text)
            return null;
          })
            .catch((err) => {
              if (typeof onError === 'function') onError(err)
              return null;
            })
        }
      }
    },
    [codeReader, onBarcode, onError]
  )

  React.useEffect(() => {
    const interval = setInterval(capture, 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <Webcam ref={webcamRef} audio={false} videoConstraints={{facingMode: 'environment'}} className='w-full h-full'/>
  )
}

export default CameraBarcodeScanner
