import React from "react";

import preval from "preval.macro";
import { AuthStatus, useAuth } from "../Contexts/AuthContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-solid-svg-icons";
import { assets, damages, jobs, photos, settings } from "../Includes/db";
import { useLiveQuery } from "dexie-react-hooks";
import { isStoragePersisted, persist } from "../Includes/persistStorage";

export const buildDateString = preval`module.exports = new Date().toUTCString();`;

export const BuildDate = (props) => {
  const [numClicks, setNumClicks] = React.useState(0);
  const [showDebug, setShowDebug] = React.useState(false);
  const [persistentStorage, setPersistentStorage] = React.useState(false);
  const [storageInfo, setStorageInfo] = React.useState(false);

  const doOnClick = () => {
    setNumClicks(numClicks + 1);
  };

  React.useEffect(() => {
    if (showDebug && !storageInfo) {
      navigator.storage.estimate().then((estimate) => {
        // console.log(((estimate.usage / estimate.quota) * 100).toFixed(2));
        // console.log((estimate.quota / 1024 / 1024).toFixed(2) + "MB");
        setStorageInfo({
          usageMB: (estimate.usage / 1024 / 1024).toFixed(2),
          usagePercent: ((estimate.usage / estimate.quota) * 100).toFixed(2),
          quotaMB: (estimate.quota / 1024 / 1024).toFixed(2),
        });
      });
    }
  }, [showDebug]);

  React.useEffect(() => {
    if (numClicks > 4) {
      setShowDebug(true);
    }
  }, [numClicks]);

  const auth = useAuth();

  const onTryPersist = async () => {
    await persist();
    const isPersisted = await isStoragePersisted();
    setPersistentStorage(isPersisted);
  };

  const dbCounts = useLiveQuery(async () => {
    const [photoQueueSize, jobsCount, assetsCount, damagesCount, photosCount, bundledPhotos, pendingPhotos, pendingJobs] = await Promise.all([
      settings.get({ key: "photoQueueSize" }),
      jobs.count(),
      assets.count(),
      damages.count(),
      photos.count(),
      photos.where("_bundleId").notEqual("").count(),
      photos.where("_uploadStatus").equals("pending").count(),
      jobs.where("_uploadStatus").equals("pending").count(),
    ]);

    return {
      photoQueueSize,
      jobsCount,
      assetsCount,
      damagesCount,
      photosCount,
      bundledPhotos,
      pendingPhotos,
      pendingJobs,
    };
  });

  return (
    <>
      <div onClick={doOnClick} className={"select-none"} {...props}>
        Build: {buildDateString}
        {process.env.NODE_ENV !== "production" && "DEV MODE"}
      </div>
      {showDebug && (
        <div className="flex flex-col items-start gap-5 p-4 pt-0 m-5 text-xs text-left border-4 border-red-500 rounded">
          <div className={"flex justify-around w-full items-center"}>
            <strong className="block w-full col-span-2 m-5 text-2xl font-bold text-center text-red-600 uppercase">--- DEBUG DATA ---</strong>
            <FontAwesomeIcon onClick={() => setShowDebug(!showDebug)} icon={faTimes} className="block text-4xl text-red-400" />
          </div>
          <div className="ml-2">
            <strong>User:</strong>
            <br />
            <div className="inline-block p-1 px-2 m-1 ml-2 font-mono break-all border rounded bg-slate-100 border-slate-400">
              <AuthStatus />
            </div>
          </div>
          <div className="ml-2">
            <strong>Device ID:</strong>
            <br />
            <div className="inline-block p-1 px-2 m-1 ml-2 font-mono tracking-widest break-all border rounded bg-slate-100 border-slate-400">{auth.uniqueId}</div>
          </div>
          <div className="ml-2">
            <strong>Current API:</strong>
            <br />
            <div className="inline-block p-1 px-2 m-1 ml-2 font-mono tracking-widest break-all border rounded bg-slate-100 border-slate-400">{auth.apiUrl}</div>
          </div>
          <div className="ml-2">
            <strong>Current date string:</strong>
            <br />
            <div className="inline-block p-1 px-2 m-1 ml-2 font-mono tracking-widest break-all border rounded bg-slate-100 border-slate-400">{JSON.stringify(new Date())}</div>
          </div>
          <div className="ml-2">
            <strong>Current Timezone Offset:</strong>
            <br />
            <div className="inline-block p-1 px-2 m-1 ml-2 font-mono tracking-widest break-all border rounded bg-slate-100 border-slate-400">{new Date().getTimezoneOffset()}</div>
          </div>
          <div className="ml-2">
            <strong>Database counts:</strong>
            <br />
            <pre className="inline-block p-1 px-2 m-1 ml-2 font-mono tracking-widest border rounded bg-slate-100 border-slate-400">{JSON.stringify(dbCounts, null, " ")}</pre>
          </div>
          <div className="ml-2">
            <strong>Storage info:</strong>
            <br />
            <pre className="inline-block p-1 px-2 m-1 ml-2 font-mono tracking-widest border rounded bg-slate-100 border-slate-400">{JSON.stringify(storageInfo, null, " ")}</pre>
          </div>
          <div className="mt-2 font-bold">Storage Persistence:</div>
          <div className="ml-2">
            {persistentStorage ? (
              "Active - Storage will not be cleared except by explicit user action."
            ) : (
              <>
                Inactive - Storage may be cleared by the UA under storage pressure.{" "}
                <button onClick={onTryPersist} className="underline">
                  Try to activate
                </button>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default BuildDate;
