import axios from "axios";

import { buildDateString } from "../Components/BuildDate";

const DEFAULT_OPTS = {
  baseUrl: "//api/v1",
  // userAgent: 'APIServiceClient/1.0 (Bison Grid Ltd)',
};

/**
 * REST API Service designed for use with the AssetBison API by Bison Grid Ltd
 * Copyright © 2018 Bison Grid Ltd
 */
class ApiService {
  /**
   * Create a new instance of ApiService for a specific resource
   * @param {object} [baseUrl] - sets the uri to access the API on
   * @param {string} [userAgent] - User-Agent HTTP header value to use
   * @param {string} [resource] - resource name to use with this instance
   * @param {string} [token] - auth token to use
   * @param {object} [headers] - additional headers to add to all requests
   */
  constructor(baseUrl, userAgent, token, resource, headers, onSessionExpired) {
    this._baseUrl = `${baseUrl || DEFAULT_OPTS.baseUrl}`.replace(/\/+$/gi, "");
    this._resource = resource || ""; // resource model for this instance
    this._search = {};
    this._isReady = false;
    this._onSessionExpired = onSessionExpired;

    this._axios = axios.create({
      timeout: 5000, // 5000 = 5 seconds
      headers: {
        ...(headers || {}),
        "app-version": `AssetBison PWA (build: ${buildDateString})`,
      },
    });

    this._axios.interceptors.response.use((response) => response, this.errorResponseHandler.bind(this));

    // if (userAgent) {
    //   this.userAgent(userAgent);
    // } else if (navigator.userAgent) {
    //   this.userAgent(`${navigator.userAgent} AssetBisonPWA/1.0`);
    // }

    if (token) {
      this.token(token);
    }

    // if (headers) {
    //   Object.keys(headers).forEach((key) => {
    //     this._axios.defaults.headers.common[key] = `${headers[key]}`;
    //   });
    // }
  }

  errorResponseHandler(error) {
    // check for errorHandle config
    if ("errorHandle" in error.config && error.config.errorHandle === false) {
      return Promise.reject(error);
    }

    if (error.response && error.response.status === 401) {
      return Promise.resolve(this.removeToken())
        .then(() => {
          if (typeof this._onSessionExpired === "function") {
            return this._onSessionExpired();
          }
          return null;
        })
        .then(() => Promise.reject(error));
    }

    return Promise.reject(error);
  }

  uri(path, resource) {
    const res = `${resource || this._resource}`;
    return `${this._baseUrl}/${res && `${res}/`}${path || ""}`;
  }

  /* auth methods */

  /**
   * Get or set the auth token
   * @param {string} [token] - sets the token
   */
  token(token) {
    if (!token) {
      return this._token;
    }
    this._token = token;
    this._axios.defaults.headers.common.Authorization = `bearer ${this._token}`;
    this._isReady = true;
    return this;
  }

  /**
   * Removes the auth token
   */
  removeToken() {
    this._token = null;
    this._axios.defaults.headers.common.Authorization = null;
    this._isReady = false;
    return this;
  }

  /**
   * Request a new token from API
   */
  refreshToken() {
    return this._axios.get(this.uri("refresh", "session")).then((response) => {
      if (!response.data.token) {
        throw new Error("missing token from login attempt");
      }
      this.token(response.data.token);
      return true;
    });
  }

  /**
   * Request validation of token from API
   */
  validateToken() {
    if (!this.token()) {
      return Promise.reject(new Error("No token set"));
    }
    return this._axios.get(this.uri("validate", "session")).then((response) => response.status === 200 && response.data.message === "Session valid");
  }

  /**
   * Authenticate with API service and set up token
   * @param {string} email - login user email address
   * @param {string} password - login user password
   */
  login(email, password) {
    return this._axios.post(this.uri("login", "user"), { email, password }).then((response) => {
      if (!response.data.token) {
        throw new Error("missing token in response");
      }
      this.token(response.data.token);
      return true;
    });
  }

  /**
   * Logout of the API and dispose of token
   */
  logout() {
    if (this.token()) {
      return this._axios
        .get(this.uri("logout", "user"))
        .then(() => this.removeToken())
        .catch(() => this.removeToken());
    }
    return Promise.resolve(null);
  }

  /**
   * Authenticate with API service and set up token
   * @param {string} email - login user email address
   * @param {string} password - login user password
   */
  getDeviceToken(body) {
    return this._axios.post(this.uri("token", "device"), body).then((response) => {
      if (!response.data.token) {
        throw new Error("missing token in response");
      }
      return this.logout()
        .then(() => this.token(response.data.token))
        .then(() => response.data.token);
    });
  }

  /**
   * Quick registration of device (close to getDeviceToken)
   * @param {string} id - id of register to submit
   * @param {string} body - data to submit with
   */
  register(id, body) {
    return this._axios.post(this.uri(`${id}/submit`, "register"), body).then((response) => {
      if (!response.data.token) {
        throw new Error("missing token in response");
      }
      // return this.token(response.data.token);
      return response.data.token;
    });
  }

  /**
   * Get the current authenticated user details
   */
  user() {
    return this._axios.get(this.uri("account", "user"));
  }

  /**
   * Downloads
   * @param {string} modified - updatedAt date taken from the last downloaded configuration
   */
  downloadConfiguration(platform, modified) {
    return this._axios.get(this.uri(`download?platform=${platform}${modified ? `&modified=${modified}` : ""}`, "configuration")).then((response) => response);
  }

  /**
   * Downloads users for pin-only login
   */
  downloadUsers() {
    return this._axios.get(this.uri("download", "user")).then((response) => response);
  }

  /* query setup methods */

  /**
   * Get or set the API base URL
   * @param {string} [baseUrl] - sets the baseUrl
   */
  baseUrl(baseUrl) {
    if (!baseUrl) {
      return this._baseUrl;
    }
    this._baseUrl = baseUrl;
    return this;
  }

  /**
   * Get or set the User Agent header
   * @param {string} [baseUrl] - sets the baseUrl
   */
  userAgent(userAgent) {
    if (!userAgent) {
      return this._userAgent;
    }
    this._userAgent = userAgent;
    this._axios.defaults.headers.common["User-Agent"] = this._userAgent;
    return this;
  }

  /**
   * Get or set the resource path to query
   * @param {string} [resource] - sets the resource
   * @returns {ApiService} - Return new instance for the resource
   */
  resource(resource) {
    if (!resource) {
      return this._resource;
    }
    // this._resource = resource;
    // return this;
    return new ApiService(this._baseUrl, this._userAgent, this._token, resource, null, this._onSessionExpired);
  }

  /**
   * Resets all current instance search parameters
   */
  reset() {
    this._search = {};
    return this;
  }

  /**
   * Sets the current instance search id parameter
   * @param {string} id - the id string to set
   */
  id(id) {
    this._search.id = id;
    return this;
  }

  /**
   * Adds to the current instance search parameters
   * @param {object} search - Search paramaeters to add
   */
  search(search) {
    this._search = { ...this._search, ...search };
    return this;
  }

  /* read methods */

  /**
   * Simple API alive check
   */
  ping() {
    return this._axios.get(this.uri("ping", ""));
  }

  /**
   * Gets a list of resource records
   * @param {integer} [page] - Page offset to use, default: 1
   * @param {integer} [limit] - Number of results per page, default: 25
   * @param {string} [sort] - Fields to sort by
   */
  list(page, limit, sort) {
    return this._axios.get(this.uri(), {
      params: {
        page,
        limit,
        sort,
        ...this._search,
      },
    });
  }

  /**
   * Gets a single resource record by id
   * @param {string} [id] - Resource record id to get
   */
  one(id) {
    const i = id || this._search.id;
    return this._axios.get(this.uri(i), {
      params: this._search,
    });
  }

  /**
   * Gets a list of dates of resource record
   * @param {string} [id] - Resource record id to get
   */
  dates(id) {
    const i = id || this._search.id;
    return this._axios.get(this.uri(`${i}/dates`));
  }

  /**
   * Gets a list of results from resource record by id
   * @param {string} [id] - Resource record id to get
   */
  result(id) {
    const i = id || this._search.id;
    return this._axios.get(this.uri(`${i}/result`), { params: this._search });
  }

  /* change methods */

  /**
   * Creates a new resource record
   * @param {object} body - The body of the new resource record to create
   */
  create(body) {
    return this._axios.post(this.uri(), body);
  }

  /**
   * Bulk create new resource records
   * @param {array} body - The array of bodies of the new resource records to create
   */
  createBulk(body) {
    return this._axios.post(this.uri("bulk"), body);
  }

  /**
   * Updates a resource record
   * @param {object} body - The body of the changes to apply
   * @param {string} [id] - The resource records id to update
   */
  update(body, id) {
    const i = id || this._search.id;
    return this._axios.patch(this.uri(i), body);
  }

  /**
   * Patch multiple records
   * @param {object} body - The body of the changes to apply. values: lookup data / patch: data to patch
   */
  patchBulk(body) {
    return this._axios.patch(this.uri("bulk"), body, { params: this._search });
  }

  /**
   * Replaces a resource record completely
   * @param {object} body - The body of the changes to apply
   * @param {string} [id] - The resource record id to update
   */
  replace(body, id) {
    const i = id || this._search.id;
    return this._axios.put(this.uri(i), body);
  }

  /**
   * Marks a resource record as deleted (soft-delete)
   * @param {string} [id] - The id of the resource record to delete
   */
  delete(id) {
    const i = id || this._search.id;
    return this._axios.delete(this.uri(i));
  }

  /**
   * Uploads a file to the resource record
   * @param {string|object} formDataOrUri - String uri to file to upload or a FormData object
   * @param {function} onProgress - Function to be called when the progress changes
   * @param {string} [id] - The resource record id to update
   */
  upload(formDataOrUri, onProgress, id, filename, type) {
    const i = id || this._search.id;
    const opts =
      typeof formDataOrUri === "string"
        ? {
            uri: formDataOrUri,
            type: type || "image/jpeg",
            name: `${filename || `${id}.jpg`}`,
          }
        : formDataOrUri;
    const data = new FormData();

    data.append("file", opts);

    const config = {
      onUploadProgress(progressEvent) {
        if (typeof onProgress !== "function") {
          return;
        }
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        onProgress(percentCompleted);
      },
    };

    return this._axios.post(this.uri(`${i}/upload`), data, config);
  }
}

export default ApiService;
