import React from "react";
import { useLiveQuery } from "dexie-react-hooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRefresh } from "@fortawesome/pro-solid-svg-icons";

import { settings } from "../Includes/db";

function UpdateAvailable({ className, component }) {
  const [updating, setUpdating] = React.useState(false);

  const updateAvailable = useLiveQuery(async () => {
    const result = await settings.get({ key: "updateAvailable" });
    return result;
  });

  const onUpdateNow = async () => {
    setUpdating(true);
    const workerReady = await navigator.serviceWorker.ready;
    if (workerReady.waiting) {
      workerReady.waiting.postMessage("SKIP_WAITING");
      await settings.delete("updateAvailable");
      window.location.reload();
    } else {
      await settings.delete("updateAvailable");
      setUpdating(false);
    }
  };

  return (
    <>
      {updateAvailable && typeof component === "function" && component(onUpdateNow)}
      {(updateAvailable || updating) && !component && (
        <button disabled={updating} className={className ? className : "button slim"} onClick={updateAvailable ? onUpdateNow : () => {}}>
          {updateAvailable && <>App Update Available - Press to update now.</>}
          {updating && (
            <>
              <FontAwesomeIcon icon={faRefresh} className="animate-spin" />
              &nbsp;&nbsp;Updating...
            </>
          )}
        </button>
      )}
    </>
  );
}

export default UpdateAvailable;
