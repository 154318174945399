import { isMobile, mobileVendor, mobileModel, osName, osVersion } from "react-device-detect";

import { jobs, assets, photos, damages, settings } from "./db";
import { dataURLtoFile, get } from "./Utilities";
import * as Sentry from "@sentry/react";

const photoUploadsChannel = new BroadcastChannel("photoUploads");
const commandChannel = new BroadcastChannel("commandChannel");

const buildMeta = (metaConfig, obj, lookup) =>
  metaConfig.reduce(
    (p, c, _i) => {
      let conditionPass = true;
      if (c.condition && c.condition.length > 0) {
        conditionPass = false;
        for (let i = 0; i < c.condition.length; i++) {
          // split condition strings into lookup path and value for comparison
          const [path, value] = c.condition[i].split("=");

          if (get(lookup, path) === value) {
            conditionPass = true;
            break;
          }
        }
      }
      if (!conditionPass) return p;

      // find each meta and add to meta object per bundle.json format
      let value = obj[c.key];
      if (value) {
        if (Array.isArray(value)) {
          value = value.reduce((q, d, j) => ({ ...q, ...{ [j]: d.value ? d.value : d } }), {});
        } else if (value.value) {
          value = { 0: value.value };
        } else {
          value = { 0: value };
        }

        let type = c.lookup || c.type;

        return {
          count: p.count + 1,
          meta: {
            ...p.meta,
            ...{ [p.count]: { key: c.key, type, source: "app", value } },
          },
        };
      }
      return p;
    },
    { count: 0, meta: {} },
  ).meta;

const buildDamage = async (damage, damageMeta, job, asset) => {
  const assetData = {
    _id: damage.id,
    _createdAt: damage.date,
    _updatedAt: damage.updated,
    taken: damage.date,
    user: damage.user.id,
    job: damage.job,
    asset: damage.asset,
    area: damage.type,
    meta: buildMeta(damageMeta, damage, { job, asset }),
    photos: damage.photos,
    comment: damage.comment,
    location: damage.location,
  };

  return assetData;
};

const buildDamages = async (assetId, damageMeta, job, asset) => {
  const damagesFound = await damages.where("asset").equals(assetId).toArray();
  return await Promise.all(damagesFound.map((damage) => buildDamage(damage, damageMeta, job, asset)));
};

const buildPhoto = async (photo) => {
  const assetData = {
    _id: photo.id,
    _createdAt: photo.date,
    _updatedAt: photo.updated,
    taken: photo.date,
    user: photo.user.id,
    job: photo.job,
    asset: photo.asset,
    type: photo.type,
    uri: `${photo.type}.jpg`,
    meta: {},
    latitude: photo.latitude,
    longitude: photo.longitude,
  };

  return assetData;
};

const buildPhotos = async (assetId) => {
  const photosFound = await photos.where("asset").equals(assetId).toArray();
  let photosFiltered = photosFound.reduce((p, c, _i) => {
    if (c.image && c.image === "data:,") {
      photos.delete(c.id);
      return p;
    }
    return [...p, c];
  }, []); // remove photos without image data
  return await Promise.all(photosFiltered.map(buildPhoto));
};

const buildAsset = async (asset, config, job) => {
  const assetMeta = get(config, `inspectionConfig.types.${asset.type}.config.inspectionMeta`, []);
  const damageMeta = get(config, `inspectionConfig.types.${asset.type}.config.damageMeta`, []);

  const assetData = {
    _id: asset.id,
    _createdAt: asset.date,
    _updatedAt: asset.updated,
    user: asset.user.id,
    job: asset.job,
    identification: asset.identification,
    identificationRaw: asset.identificationRaw,
    type: asset.type,
    meta: buildMeta(assetMeta, asset, { job, asset }),
    latitude: asset.latitude,
    longitude: asset.longitude,
    signatures: {},
    photos: await buildPhotos(asset.id),
    damages: await buildDamages(asset.id, damageMeta, job, asset),
  };

  return assetData;
};

const buildAssets = async (jobId, config, job) => {
  const assetsFound = await assets.where("job").equals(jobId).toArray();
  return await Promise.all(assetsFound.map((asset) => buildAsset(asset, config, job)));
};

const buildJob = async (job, config, jobMeta) => {
  const jobData = {
    _id: job.id,
    _createdAt: job.date,
    _updatedAt: job.updated,
    utcOffset: job.utcOffset,
    user: job.user.id,
    meta: buildMeta(jobMeta, job, { job }),
    latitude: job.latitude,
    longitude: job.longitude,
    signatures: {},
    inspections: await buildAssets(job.id, config, job),
  };

  return jobData;
};

const buildJobs = async (config) => {
  const jobsFound = await jobs.filter((c) => typeof c._uploadStatus === "undefined" || c._uploadStatus === "ready").toArray();

  if (!jobsFound || jobsFound.length === 0) return false;

  await jobs
    .where("id")
    .anyOf(jobsFound.map((v) => v.id))
    .modify({ _uploadStatus: "pending" });

  return await Promise.all(
    jobsFound.map((job) => {
      const jobMeta = get(config, job.Type ? `batchConfig.types.${job.Type}.batchMeta` : "batchConfig.batchMeta", []);
      return buildJob(job, config, jobMeta);
    }),
  );
};

const buildBundle = async () => {
  const config = (await settings.get("config")).value.config;
  return await buildJobs(config);
};

let uploadPhotosRunning = false;
let queuePhotosRunning = false;
export const uploadPhotos = async () => {
  if (uploadPhotosRunning) {
    queuePhotosRunning = true;
    return 0;
  }
  uploadPhotosRunning = true;
  queuePhotosRunning = false;
  const pendingPhotos = await photos.where("_uploadStatus").equals("pending").toArray();
  let filteredPhotos = [];

  if (pendingPhotos && pendingPhotos.length > 0) {
    filteredPhotos = pendingPhotos.reduce((p, c, _i) => {
      if (c.image && c.image === "data:,") {
        photos.delete(c.id);
        return p;
      }
      return [...p, c];
    }, []); // remove photos without image data

    for (let index = 0; index < filteredPhotos.length; index++) {
      const photo = filteredPhotos[index];
      const apiUrl = await settings.get("apiUrl");
      // if (window.location.hostname === "localhost") {
      // Sentry.captureMessage(`Doing localhost upload (${window.location.hostname})`);
      const [token, uniqueId] = await settings.bulkGet(["token", "uniqueId"]);
      try {
        let file;
        if (photo?.image) {
          file = dataURLtoFile(photo.image, `${photo.id}.jpg`);
        } else {
          let photoId = photo.id;
          try {
            const opfsRoot = await navigator.storage.getDirectory();
            const photosDirectory = await opfsRoot.getDirectoryHandle("photos");
            const photoFileHandle = await photosDirectory.getFileHandle(`${photoId}.jpg`);
            file = await photoFileHandle.getFile();
          } catch (e) {
            console.error("failed to load image file");
            // Sentry.captureException(e, "Fail to load image file");
          }
        }

        if (!file) throw new Error(`No file to upload in PhotoID: ${photo.id} for bundle: ${photo._bundleId}`);

        const body = new FormData();
        body.append("file", file);

        const response = await fetch(`${apiUrl.value}/bundle/${photo._bundleId}/upload?id=${photo.id}`, {
          method: "POST",
          body,
          headers: { Authorization: `bearer ${token.value}` },
        });

        // done, delete photo
        if (response.ok) {
          await photos.update(photo.id, { _uploadStatus: "queued" });
        } else {
          throw new Error(`Failed to queue photo upload`);
        }
      } catch (error) {
        // await photos.update(photo.id, { _uploadStatus: "queued" });
        console.error({ message: "Photo upload error", error });
        // break;
      }
      // } else {
      //   photoUploadsChannel.postMessage({
      //     id: photo.id,
      //     url: `${apiUrl.value}/bundle/${photo._bundleId}/upload`,
      //     image: photo.image || false,
      //     filename: `${photo.id}.jpg`,
      //   });
      // }
    }
  }

  setTimeout(() => {
    commandChannel.postMessage({ action: "uploadPhotos" });
  }, 2000);

  uploadPhotosRunning = false;
  if (queuePhotosRunning) {
    uploadPhotos();
  }

  return filteredPhotos.length;
};

let requeuePhotosRunning = false;
// let queueRequeuePhotosRunning = false;
export const requeuePhotos = async () => {
  if (requeuePhotosRunning) {
    // queueRequeuePhotosRunning = true;
    return 0;
  }
  requeuePhotosRunning = true;
  // queueRequeuePhotosRunning = false;
  const photosToRequeue = await photos.where("_uploadStatus").equals("queued").or("_uploadStatus").equals("pending").toArray();

  if (photosToRequeue && photosToRequeue.length > 0) {
    // remove photos without image data

    for (let index = 0; index < photosToRequeue.length; index++) {
      const photo = photosToRequeue[index];
      const apiUrl = await settings.get("apiUrl");
      const token = await settings.get("token");
      try {
        let file;
        if (photo?.image) {
          file = dataURLtoFile(photo.image, `${photo.id}.jpg`);
        } else {
          let photoId = photo.id;
          try {
            const opfsRoot = await navigator.storage.getDirectory();
            const photosDirectory = await opfsRoot.getDirectoryHandle("photos");
            const photoFileHandle = await photosDirectory.getFileHandle(`${photoId}.jpg`);
            file = await photoFileHandle.getFile();
          } catch (e) {
            console.error("failed to load image file");
          }
        }

        if (!file) throw new Error(`No file to requeue in PhotoID: ${photo.id} for bundle: ${photo._bundleId}`);

        const body = new FormData();
        body.append("file", file);

        const response = await fetch(`${apiUrl.value}/bundle/${photo._bundleId}/upload?id=${photo.id}`, {
          method: "POST",
          body,
          headers: { Authorization: `bearer ${token.value}` },
        });

        // done, delete photo
        if (response.ok) {
          await photos.update(photo.id, { _uploadStatus: "queued" });
        } else {
          throw new Error(`Failed to requeue photo upload`);
        }
      } catch (error) {
        console.log({ message: "Photo requeue error", error });
        Sentry.captureException(error);
      }
    }
  }

  setTimeout(() => {
    commandChannel.postMessage({ action: "uploadPhotos" });
  }, 2000);

  // requeuePhotosRunning = false;
  // if (queueRequeuePhotosRunning) {
  //   requeuePhotos();
  // }

  return photosToRequeue.length;
};

const finishing = [];
export const finish = async (body, responseBody) => {
  if (finishing.includes(responseBody._id)) return;
  finishing.push(responseBody._id);
  // grab all ID's
  const ids = JSON.parse(body.bundle).reduce(
    (p, c, _i) => {
      // add job id
      p.jobs.push(c._id);

      // loop inspections
      c.inspections.forEach((inspection) => {
        // add inspection id
        p.assets.push(inspection._id);
        // -- loop damages
        inspection.damages.forEach((damage) => {
          p.damages.push(damage._id);
        });
        // -- loop photos
        inspection.photos.forEach((photo) => {
          p.photos.push(photo._id);
        });
      });

      // return
      return p;
    },
    { jobs: [], assets: [], damages: [], photos: [] },
  );

  // remove bundled job, inspection and damage data
  await jobs.where("id").anyOf(ids.jobs).delete();
  await assets.where("id").anyOf(ids.assets).delete();
  await damages.where("id").anyOf(ids.damages).delete();

  // update photos with bundleId
  const bundledPhotos = await photos.where("id").anyOf(ids.photos).toArray();
  const photosToUpdate = bundledPhotos.filter((p) => p._uploadStatus !== "queued").map((p) => p.id);

  await photos.where("id").anyOf(photosToUpdate).modify({ _bundleId: responseBody._id, _uploadStatus: "pending" });

  const index = finishing.indexOf(responseBody._id);
  if (index > -1) {
    finishing.splice(index, 1);
  }

  return uploadPhotos();
};

export const start = async () => {
  // check if there is anything to upload
  const bundle = await buildBundle();

  // api settings
  const [token, apiUrl, uniqueId] = await settings.bulkGet(["token", "apiUrl", "uniqueId"]);

  // api
  // const bundleApi = new ApiService(apiUrl.value, '', token.value, 'bundle');

  if (bundle && bundle.length > 0) {
    const make = isMobile ? (mobileVendor ? mobileVendor : "mobile") : "desktop"; // Make
    const model = isMobile ? (mobileModel ? mobileModel : "mobile") : "desktop"; // Model
    const deviceOS = `${osName} ${osVersion}`; // OS

    const body = {
      appName: "AssetBisonPWA",
      appVersion: "0.1",
      deviceName: `${make} ${model}`,
      deviceOS,
      deviceSerial: uniqueId.value,
      client: null, // todo
      schemaVersion: "1",
      bundle: JSON.stringify(bundle),
      status: "closed",
    };

    let response;
    try {
      response = await fetch(`${apiUrl.value}/bundle`, {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
          Authorization: `bearer ${token.value}`,
          "Content-Type": "application/json",
        },
      });
    } catch (error) {
      return { status: "queue", jobs: bundle.length, photos: 0 };
    }

    const responseBody = await response.json();

    let status = "ok";
    let photosUploading = 0;

    if (response.status === 401) status = "unauthorised";
    if (response.status === 400) status = "invalid";
    if (response.status !== 200) status = "queue";
    if (!responseBody?._id) status = "missing_id";

    if (status === "ok") {
      photosUploading = await finish(body, responseBody);
    }

    return { status, jobs: bundle.length, photos: photosUploading };
  } else {
    const photosUploading = await uploadPhotos();
    return { status: "ok", jobs: 0, photos: photosUploading };
  }
};
